.promo p {
  text-align: center;
  font-size: 1.2rem;
  padding-left: 10%;
  padding-right: 10%;
}

.promo a {
  margin-right: 0;
}

.promo-link {
  color: rgb(11, 49, 198);
  text-decoration: none;
  cursor: pointer;
}

.promo-link:hover {
  color: rgb(46, 78, 251);
  text-decoration: none;
  cursor: pointer;
}

.undervaluedbooks-link {
  color: rgb(8, 176, 35);
  text-decoration: none;
  cursor: pointer;
}

.undervaluedbooks-link:hover {
  color: rgb(70, 248, 62);
  text-decoration: none;
  cursor: pointer;
}

.undervaluedmusic-link {
  color: rgb(199, 30, 32);
  text-decoration: none;
  cursor: pointer;
}

.undervaluedmusic-link:hover {
  color: rgb(233, 50, 30);
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .promo p {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .promo p {
    font-size: 1rem;
  }
}
